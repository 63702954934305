import { Component, computed,  ElementRef,  inject } from '@angular/core';
import { UiSelectService } from '../ui-select.service';
import { CommonModule, DOCUMENT } from '@angular/common';

@Component({
  selector: 'ui-select-content',
  templateUrl: './ui-select-content.component.html',
  styleUrl: './ui-select-content.component.css',
  imports: [CommonModule],
  standalone: true
})
export class UiSelectContentComponent {
  private _ref = inject(ElementRef);
  private DOCUMENT = inject(DOCUMENT);
  private _selectService = inject(UiSelectService);
  public open = computed(() => this._selectService.open());

  public isRightPosition = computed(() => {
    if(!this.open())
      return false;

    const windowWidth = this.DOCUMENT.documentElement.getBoundingClientRect().width;
    const contentHolder = this._ref.nativeElement.querySelector('.content-holder');
    const boxRight = contentHolder.getBoundingClientRect().right;
    
   return (windowWidth < boxRight) && this.open();
  });

  public isBottomPosition = computed(() => {
    if (!this.open()) return true;
  
    const modalHeight = 327; // Holder max height
    const offset = 60; // 56 Trigger height + 4px offset
    const windowHeight = this.DOCUMENT.documentElement.clientHeight;
  
    const { bottom: buttonBottom, top: buttonTop } = this._ref.nativeElement
      .querySelector('.select-content')
      .getBoundingClientRect();
  
    const spaceBelow = windowHeight - buttonBottom;
    const spaceAbove = buttonTop - offset;
  
    return spaceBelow >= modalHeight || spaceAbove < modalHeight;
  });

  public maxHeightStyle = computed(() => {
    if (!this.open()) return {'max-height': '300px'};
    
    const windowHeight = this.DOCUMENT.documentElement.clientHeight;
    const contentHolder = this._ref.nativeElement.querySelector('.content-holder');
    const boxTop = contentHolder.getBoundingClientRect().top;

    const spaceBelow = windowHeight - boxTop;
    
    const desiredMaxHeight = 300;
    const adjustedMaxHeight = Math.min(spaceBelow - 22, desiredMaxHeight);
    
    return { 'max-height': `${adjustedMaxHeight}px` };
  });

  public contentHolderClasses = computed(() => ({
    'content-holder--right': this.isRightPosition(),
    'content-holder--left': !this.isRightPosition(),
    'content-holder--bottom': !this.isBottomPosition(),
    'content-holder--top': this.isBottomPosition(),
    'content-holder--open': this.open()
  }));
}